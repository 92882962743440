import React, { Component } from "react";

class AdvancedFilter extends Component {
	handleCategory = () => {
		const { categories } = this.props;
		if (categories) {
			return categories?.map((dt, idx) => (
				<option key={idx} value={dt.id}>
					{dt.name}
				</option>
			));
		}
		return;
	};

	render() {
		const { handleForm, category, date_from, date_to, sort_by, sort_type } = this.props;
		return (
			<>
				<div className="w-100 d-flex flex-row justify-content-between form-layout form-layout-6 mb-3">
					<div className="row w-100 no-gutters mr-3">
						<div className="col-5 col-sm-4 p-0 py-2 pl-3 text-capitalize">Sender:</div>
						<div className="col-7 col-sm-8 p-0 py-2 pl-3">
							<input
								className="form-control"
								type="text"
								name="from"
								placeholder="Write here ..."
								onChange={handleForm}
							/>
						</div>
					</div>
					<div className="row w-100 no-gutters mr-3">
						<div className="col-5 col-sm-4 p-0 py-2 pl-3 text-capitalize">Title:</div>
						<div className="col-7 col-sm-8 p-0 py-2 pl-3">
							<input
								className="form-control"
								type="text"
								name="title"
								placeholder="Write here ..."
								onChange={handleForm}
							/>
						</div>
					</div>
					<div className="row w-100 no-gutters">
						<div className="col-5 col-sm-4 p-0 py-2 pl-3 text-capitalize">Conversation:</div>
						<div className="col-7 col-sm-8 p-0 py-2 pl-3">
							<input
								className="form-control"
								type="text"
								name="convo"
								placeholder="Write here ..."
								onChange={handleForm}
							/>
						</div>
					</div>
				</div>
				<div className="d-flex align-items-center wd-100p advanced">
					<select
						id="search-category"
						onChange={handleForm}
						value={category}
						name="category"
						className="form-control wd-300"
					>
						<option value="">All Category</option>
						{this.handleCategory()}
					</select>
					<div className="input-group mx-2" style={{ flexBasis: "20%" }}>
						<div className="input-group-prepend">
							<div className="input-group-text">
								<i className="icon ion-calendar tx-16 lh-0 op-6"></i>
							</div>
						</div>
						<input
							type="date"
							name="date_from"
							value={date_from}
							onChange={handleForm}
							className="form-control "
						/>
					</div>
					<span className="mr-2"> - </span>
					<div className="input-group mr-2" style={{ flexBasis: "20%" }}>
						<div className="input-group-prepend">
							<div className="input-group-text">
								<i className="icon ion-calendar tx-16 lh-0 op-6"></i>
							</div>
						</div>
						<input
							type="date"
							name="date_to"
							value={date_to}
							onChange={handleForm}
							className="form-control"
						/>
					</div>
					<span className="wd-60 mr-2">Sort by: </span>
					<select className="form-control wd-150" value={sort_by} onChange={handleForm} name="sort_by">
						<option value="">- Default -</option>
						<option value="age">Age</option>
						<option value="last_update">Last Update</option>
					</select>
					<select className="form-control wd-150" value={sort_type} onChange={handleForm} name="sort_type">
						<option value="">- Default -</option>
						<option value="asc">ASC</option>
						<option value="desc">DESC</option>
					</select>
				</div>
			</>
		);
	}
}

export default AdvancedFilter;
