import React, { Component } from "react";
import TopNav from "./components/TopNav";
import Search from "./components/Search";
import Card from "./components/Card";
import AdvancedFilter from "./components/AdvancedFilter";
import Pagination from "partial/Pagination";
import Title from "partial/Title";
import DetailModal from "./components/Modal";

// REDUX
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "redux/actions/ticket";
import * as actionsMain from "redux/actions/main";

class Tickets extends Component {
	state = {
		toggle_advanced: false
	};

	toggle_advanced = (e) => {
		e.preventDefault();
		const { toggle_advanced } = this.state;
		this.setState({
			toggle_advanced: !toggle_advanced
		});
	};

	renderTicket = () => {
		const { ticket, main, actions } = this.props;

		if (ticket.ticket_data) {
			let ticket_data = ticket.ticket_data;
			return ticket_data.map((dt, idx) => (
				<Card
					{...dt}
					user={main.user}
					key={idx}
					get_data={() => actions.get_ticket_detail(dt.ticket_number)}
					move_ticket={actions.move_ticket}
				/>
			));
		}
	};

	componentDidMount() {
		const { actions, mainActions, match } = this.props,
			other_state = actions.map_state(match.params.type);

		actions.get_ticket({
			...other_state,
			page: 1
		});

		mainActions.get_status();
	}

	handleTabChange = (type) => {
		const { actions } = this.props,
			other_state = actions.map_state(type);

		actions.get_ticket({
			...other_state,
			page: 1
		});
	};

	handleChange = (data) => {
		const { selected } = data;
		const { actions } = this.props;

		actions.get_ticket({
			page: selected + 1
		});
	};

	handleSearch = (e) => {
		e.preventDefault();
		const { actions } = this.props;
		actions.get_ticket();
	};

	handleClear = () => {
		const { actions } = this.props;
		actions.get_ticket(false, true);
	};

	handleForm = (e) => {
		const { actions } = this.props;
		let key = e.currentTarget.name,
			val = e.currentTarget.value;

		if (key === "query") {
			const isEmpty = val === "";
			const isNum = /^\d+$/.test(val);

			if (!isEmpty && !isNum) return;
		}

		actions.map_data({
			[key]: val
		});
	};

	render() {
		const { toggle_advanced } = this.state;
		const { ticket, main, match, actions } = this.props;

		return (
			<div id="all-ticket" className="mb-5">
				<Title>Tickets List</Title>
				<div className="nav-statistics-wrapper mb-3">
					<TopNav type={match.params.type} handleTabChange={this.handleTabChange} total={ticket.total_data} />
				</div>
				<div className="section-wrapper search_pagination-cont px-3 py-3 mb-3">
					<form onSubmit={this.handleSearch}>
						<div className="search_pagination d-flex align-items-center">
							<Search {...ticket} handleForm={this.handleForm} />

							<button type="submit" className="btn btn-primary wd-40">
								<i className="ion-ios-search-strong"></i>
							</button>

							<button onClick={this.handleClear} className="btn btn-danger wd-40 ml-1">
								<i className="ion-android-refresh"></i>
							</button>

							<a href=" " className="tx-11 ml-2 wd-100" onClick={this.toggle_advanced}>
								Toggle Advanced Search
							</a>

							<Pagination
								pageCount={ticket.total_page}
								currentPage={ticket.current_page - 1}
								handleChange={this.handleChange}
							/>
						</div>
						<div
							className={`advanced_filter flex-column align-items-center ${
								toggle_advanced ? "d-flex" : "d-none"
							} mt-3`}
						>
							<AdvancedFilter {...ticket} categories={main.categories} handleForm={this.handleForm} />
						</div>
					</form>
				</div>
				<div className="ticket-list-cont tx-center">{this.renderTicket()}</div>

				{ticket.ticket_detail && (
					<DetailModal
						categories={main.categories}
						ticket_detail={ticket.ticket_detail}
						conversation={ticket.conversation}
						add_conversation={actions.add_conversation}
						reset_detail={actions.reset_detail}
						get_ticket_detail={actions.get_ticket_detail}
						append_chat={actions.append_chat}
						status={main.status}
					/>
				)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		ticket: state.ticket,
		main: state.main
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch),
		mainActions: bindActionCreators(actionsMain, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);
